import React, { useEffect, useState, Suspense } from "react";
import { SnackbarProvider } from "notistack";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import Routes from "./Routes";
import ScrollToTop from "./components/ScrollToTop";
import Loading from "./components/Loading";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
 
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to disable Ctrl + Shift + I
    const handleKeyDownI = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.key === "I"
      ) {
        event.preventDefault();
      }
    };

    // Function to disable F12
    const handleKeyDownF12 = (event) => {
      if (event.key === "F12") {
        event.preventDefault();
      }
    };

    // Function to disable Ctrl + Shift + J
    const handleKeyDownJ = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.key === "J"
      ) {
        event.preventDefault();
      }
    };

    // Function to disable Ctrl + Shift + C
    const handleKeyDownC = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.key === "C"
      ) {
        event.preventDefault();
      }
    };

    // Function to disable Ctrl + U
    const handleKeyDownU = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "U") {
        event.preventDefault();
      }
    };

    // Function to handle context menu
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Set a timer to stop loading after 3800 milliseconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1300);

    // Add event listeners
    document.addEventListener("keydown", handleKeyDownI);
    document.addEventListener("keydown", handleKeyDownF12);
    document.addEventListener("keydown", handleKeyDownJ);
    document.addEventListener("keydown", handleKeyDownC);
    document.addEventListener("keydown", handleKeyDownU);
    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup by removing event listeners and clearing the timer
    return () => {
      document.removeEventListener("keydown", handleKeyDownI);
      document.removeEventListener("keydown", handleKeyDownF12);
      document.removeEventListener("keydown", handleKeyDownJ);
      document.removeEventListener("keydown", handleKeyDownC);
      document.removeEventListener("keydown", handleKeyDownU);
      document.removeEventListener("contextmenu", handleContextMenu);
      clearTimeout(timer);
    };
  }, []); // Empty dependency array to run the effect only once during component mount

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <SnackbarProvider maxSnack={3}>
            <ScrollToTop />
            <Suspense>
              <Routes />
            </Suspense>
          </SnackbarProvider>
          <Analytics />
          <SpeedInsights />
        </>
      )}
    </>
  );
}

export default App;
